export const tournaments = [
    
    { id: "1", name: "IPL-1", url: "/ipl" },
    { id: "2", name: "U600 13", url: "/u600-13" },
    { id: "3", name: "IPL-2", url: "/ipl2" },
    { id: "4", name: "U600 14", url: "/u600-14" },
    { id: "6", name: "IPL-3", url: "/ipl-3" },
    { id: "7", name: "IPL-4", url: "/ipl-4" },
    { id: "9", name: "U600-15", url: "/u600-15" },
    { id: "8", name: "IPL-5", url: "/ipl-5" },
    { id: "11", name: "U600-16", url: "/u600-16" },
    {id: "10", name: "IPL-6", url: "/ipl-6" }
    

    
    // add more here...
  ];
   